import React, { useState } from 'react';

import {
MDBBtn,
MDBCard,
MDBCardBody,
MDBCardHeader,
MDBCardImage,
MDBCol,
MDBContainer,
MDBIcon,
MDBInput,
MDBListGroup,
MDBListGroupItem,
MDBRipple,
MDBRow,
MDBTooltip,
MDBTypography,
MDBNavbar,
MDBNavbarNav,
MDBNavbarItem,
MDBNavbarLink,
MDBNavbarToggler,
MDBModal, MDBModalDialog, MDBModalContent, MDBModalHeader, MDBModalTitle, MDBModalBody, MDBModalFooter ,
MDBProgress ,
MDBCollapse,
MDBRadio ,

  MDBCheckbox,
  MDBAccordion, MDBAccordionItem


} from "mdb-react-ui-kit";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import './Dashboard.css'
import axios from 'axios';
import img from "../../src/images/wisemen.png"

import img2 from "../../src/images/flyrr.jpeg"
import img3 from "../../src/images/fly1.jpeg"

import eco from "../../src/images/208274.svg"
import zim from "../../src/images/zimswitch.svg"

import innbucks from "../../src/images/innbucks.png"

import visa from "../../src/images/visa.svg"
import { useNavigate } from 'react-router-dom';


import {Link } from "react-router-dom";

export const Dashboard =()=>{
  const [showBasic, setShowBasic] = useState(false);
  const [basicModal, setBasicModal] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [showBasicy, setShowBasicy] = useState(false);
  const [basicModaly, setBasicModaly] = useState(false);
 
  const [basicModaly1, setBasicModaly1] = useState(false);
  const [basicModaly2, setBasicModaly2] = useState(false);
  const [basicModaly3, setBasicModaly3] = useState(false);
  const [basicModaly4, setBasicModaly4] = useState(false);
  const [basicModaly5, setBasicModaly5] = useState(false);
  const [showDiv, setShowDiv] = useState(false);
   
  const [showNot, setShowNot] = useState(false);
  const [showName, setShowName] = useState(false);
  const [apiData, setApiData] = useState(null);
  const [errors, setErrors] = useState({});
  const [selectedValue, setSelectedValue] = useState('');

  const handleChange = (value) => {
    setSelectedValue(value);
  }
 
  

  const notify = () => toast("This is a toast notification !");
  const validateFormData = () => {
    const errors = {};
    if (!formData.name) {
      errors.name = "Please enter the ticket holder's name";
   
    }
    if (!formData.surname) {
      errors.surname = "Please enter the ticket holder's surname";
    }
    if (!formData.idnum) {
      errors.idnum = "Please enter the ticket holder's ID";
    }
    if (!formData.email) {
      errors.email = "Please enter a valid email address";
    }
    if (!formData.phone) {
      errors.phone = "Please enter the phone number";
    }
    return errors;
  };

  const [formData, setFormData] = useState({ name: '', surname: '' ,email:'',phone:'' ,idnum:''});
  const toggleOpen = () => {setBasicModal(!basicModal) ;    
    setFormData({name: '', surname: '' ,email:'',phone:'',idnum:'' })
    setSelectedValue('');
    setShowDiv(false)
    setShowNot(false)
    
    }

    const toggleOpeny = () => {setBasicModaly(!basicModaly) ;    
      setFormData({name: '', surname: '' ,email:'',phone:'',idnum:'' })
      setSelectedValue('');
      setShowDiv(false)
      setShowNot(false)
      
      }

      const toggleOpeny1 = () => {setBasicModaly1(!basicModaly1) ;    
        setFormData({name: '', surname: '' ,email:'',phone:'',idnum:'' })
        setSelectedValue('');
        setShowDiv(false)
        setShowNot(false)
        
        }

        const toggleOpeny2 = () => {setBasicModaly2(!basicModaly2) ;    
          setFormData({name: '', surname: '' ,email:'',phone:'',idnum:'' })
          setSelectedValue('');
          setShowDiv(false)
          setShowNot(false)
          
          }

          const toggleOpeny3 = () => {setBasicModaly3(!basicModaly3) ;    
            setFormData({name: '', surname: '' ,email:'',phone:'',idnum:'' })
            setSelectedValue('');
            setShowDiv(false)
            setShowNot(false)
            
            }

            const toggleOpeny4 = () => {setBasicModaly4(!basicModaly4) ;    
              setFormData({name: '', surname: '' ,email:'',phone:'',idnum:'' })
              setSelectedValue('');
              setShowDiv(false)
              setShowNot(false)
              
              }

              const toggleOpeny5 = () => {setBasicModaly5(!basicModaly5) ;    
                setFormData({name: '', surname: '' ,email:'',phone:'',idnum:'' })
                setSelectedValue('');
                setShowDiv(false)
                setShowNot(false)
                
                }
  const handleClick = async () => {

    const errors = validateFormData();
    if (Object.keys(errors).length > 0) {
      setShowName(!showName)
     // setIsDisabled(true);
    }else {
      // Send data to API
      setShowName(false)
      setIsDisabled(true);
    setShowNot(!showNot)
    let post_data = {
      name: formData.name,
      surname: formData.surname,
      email: formData.email,
      phone: formData.phone,
      idnum:formData.idnum,
      currency: "USD",
      tid: 1,
      tname:"USD200",
      doneby:"website",
      amount:"200",
      size:selectedOption
    };
    try {
      let order_responce = await axios.post(
        "https://onlinecombinedbackend.onrender.com/online/web",
        post_data
      );
      let order = order_responce.data;
      let success = order.success;
      
      if (success === "true") {
       
        console.log(order.data)
      setApiData(order.data);
      console.log(order.message)
      setShowDiv(true);


    }
    else {
      setApiData(order.message);
    
      setShowDiv(true);
    
    }
  } catch (error) {
    console.error(error);
    
    setApiData(order.message);
    
    setShowDiv(true);
  }
  finally{
    setIsDisabled(false);
  }
    }
    
}


//combo
const handleClicky = async () => {
  

  const errors = validateFormData();
    if (Object.keys(errors).length > 0) {
      setShowName(!showName)
    }else {
      // Send data to API
      setShowName(false)
  setIsDisabled(true);
  setShowNot(!showNot)
  let post_data = {
    name: formData.name,
    surname: formData.surname,
    email: formData.email,
    phone: formData.phone,
    idnum:formData.idnum,
    currency: "USD",
    tid: 1,
    tname:"USD150",
    doneby:"website",
    amount:"150",
    size:selectedOption
  };
  try {
    let order_responce = await axios.post(
      "https://onlinecombinedbackend.onrender.com/online/web",
      post_data
    );
    let order = order_responce.data;
    let success = order.success;
    
    if (success === "true") {
     
      console.log(order.data)
    setApiData(order.data);
    console.log(order.message)
    setShowDiv(true);


  }
  else {
    setApiData(order.message);
  
    setShowDiv(true);
  
  }
} catch (error) {
  console.error(error);
  
  setApiData(order.message);
  
  setShowDiv(true);
}

finally{
  setIsDisabled(false);
}
}
}


//100

const handleClicky1 = async () => {

  const errors = validateFormData();
    if (Object.keys(errors).length > 0) {
      setShowName(!showName)
    }else {
      // Send data to API
      setShowName(false)
  setIsDisabled(true);
  setShowNot(!showNot)
  let post_data = {
    name: formData.name,
    surname: formData.surname,
    email: formData.email,
    phone: formData.phone,
    idnum:formData.idnum,
    currency: "USD",
    tid: 1,
    tname:"USD100",
    doneby:"website",
    amount:"100",
    size:selectedOption
  };
  try {
    let order_responce = await axios.post(
      "https://onlinecombinedbackend.onrender.com/online/web",
      post_data
    );
    let order = order_responce.data;
    let success = order.success;
    
    if (success === "true") {
     
      console.log(order.data)
    setApiData(order.data);
    console.log(order.message)
    setShowDiv(true);


  }
  else {
    setApiData(order.message);
  
    setShowDiv(true);
  
  }
} catch (error) {
  console.error(error);
  
  setApiData(order.message);
  
  setShowDiv(true);
}

finally{
  setIsDisabled(false);
}
}
}

//navigate



//combo

const handleClicky2 = async () => {
  const errors = validateFormData();
    if (Object.keys(errors).length > 0) {
      setShowName(!showName)
    }else {
      // Send data to API
      setShowName(false)
  setIsDisabled(true);
  setShowNot(!showNot)
  let post_data = {
    name: formData.name,
    surname: formData.surname,
    email: formData.email,
    phone: formData.phone,
    idnum:formData.idnum,
    currency: "USD",
    tid: 1,
    tname:"USD50",
    doneby:"website",
    amount:"50",
    size:selectedOption
  };
  try {
    let order_responce = await axios.post(
      "https://onlinecombinedbackend.onrender.com/online/web",
      post_data
    );
    let order = order_responce.data;
    let success = order.success;
    
    if (success === "true") {
     
      console.log(order.data)
    setApiData(order.data);
    console.log(order.message)
    setShowDiv(true);


  }
  else {
    setApiData(order.message);
  
    setShowDiv(true);
  
  }
} catch (error) {
  console.error(error);
  
  setApiData(order.message);
  
  setShowDiv(true);
}

finally{
  setIsDisabled(false);
}
}
}

//combo

const handleClicky3 = async () => {

  const errors = validateFormData();
    if (Object.keys(errors).length > 0) {
      setShowName(!showName)
    }else {
      // Send data to API
      setShowName(false)
  setIsDisabled(true);
  setShowNot(!showNot)
  let post_data = {
    name: formData.name,
    surname: formData.surname,
    email: formData.email,
    phone: formData.phone,
    idnum:formData.idnum,
    currency: "USD",
    tid: 1,
    tname:"USD25",
    doneby:"website",
    amount:"25",
    size:selectedOption
  };
  try {
    let order_responce = await axios.post(
      "https://onlinecombinedbackend.onrender.com/online/web",
      post_data
    );
    let order = order_responce.data;
    let success = order.success;
    
    if (success === "true") {
     
      console.log(order.data)
    setApiData(order.data);
    console.log(order.message)
    setShowDiv(true);


  }
  else {
    setApiData(order.message);
  
    setShowDiv(true);
  
  }
} catch (error) {
  console.error(error);
  
  setApiData(order.message);
  
  setShowDiv(true);
}
finally{
  setIsDisabled(false);
}
}
}


//combo

const handleClicky4 = async () => {
  const errors = validateFormData();
    if (Object.keys(errors).length > 0) {
      setShowName(!showName)
    }else {
      // Send data to API
      setShowName(false)
  setIsDisabled(true);
  setShowNot(!showNot)
  let post_data = {
    name: formData.name,
    surname: formData.surname,
    email: formData.email,
    phone: formData.phone,
    idnum:formData.idnum,
    currency: "USD",
    tid: 1,
    tname:"USD10",
    doneby:"website",
    amount:"10",
    size:selectedOption
  };
  try {
    let order_responce = await axios.post(
      "https://onlinecombinedbackend.onrender.com/online/web",
      post_data
    );
    let order = order_responce.data;
    let success = order.success;
    
    if (success === "true") {
     
      console.log(order.data)
    setApiData(order.data);
    console.log(order.message)
    setShowDiv(true);


  }
  else {
    setApiData(order.message);
  
    setShowDiv(true);
  
  }
} catch (error) {
  console.error(error);
  
  setApiData(order.message);
  
  setShowDiv(true);
}
finally{
  setIsDisabled(false);
}
}
}

const handleClicky5 = async () => {

  const errors = validateFormData();
    if (Object.keys(errors).length > 0) {
      setShowName(!showName)
    }else {
      // Send data to API
      setShowName(false)
  setIsDisabled(true);
  setShowNot(!showNot)
  let post_data = {
    name: formData.name,
    surname: formData.surname,
    email: formData.email,
    phone: formData.phone,
    idnum:formData.idnum,
    currency: "USD",
    tid: 1,
    tname:"online",
    doneby:"website",
    amount:"5",
    size:selectedOption
  };
  try {
    let order_responce = await axios.post(
      "https://onlinecombinedbackend.onrender.com/online/web",
      post_data
    );
    let order = order_responce.data;
    let success = order.success;
    
    if (success === "true") {
     
      console.log(order.data)
    setApiData(order.data);
    console.log(order.message)
    setShowDiv(true);


  }
  else {
    setApiData(order.message);
  
    setShowDiv(true);
  
  }
} catch (error) {
  console.error(error);
  
  setApiData(order.message);
  
  setShowDiv(true);
}

finally{
  setIsDisabled(false);
}
}

}
  const [showProgress, setShowProgress] = useState(false);

  // const handleClick = () => {
    
  //   setShowDiv(!showDiv);
  //   setShowNot(!showNot)
  //  console.log(formData)

  // }

  const handleChangey = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    console.log(formData)
  }



  const [selectedOption, setSelectedOption] = useState("NA");

  const handleSelectChange = (event) => {
    setSelectedOption(event.target.value);
   console.log (selectedOption)
  };

  const navigate = useNavigate();

  const handleButtonClick = () => {
    navigate('(link unavailable)');
  };

    return(<>
<section className="h-100 gradient-custom">
        <header>
      <MDBNavbar expand='lg' light bgColor='white'>
        <MDBContainer fluid>
          <MDBNavbarToggler
            onClick={() => setShowBasic(!showBasic)}
            aria-controls='navbarExample01'
            aria-expanded='false'
            aria-label='Toggle navigation'
          >
            <MDBIcon fas icon='bars' />
          </MDBNavbarToggler>
          <MDBCollapse navbar show={showBasic}>
            {/* <MDBNavbarNav right className='mb-2 mb-lg-0'>
              <MDBNavbarItem active>
                <MDBNavbarLink aria-current='page' href='#'>
                  Home
                </MDBNavbarLink>
              </MDBNavbarItem>
              // <MDBNavbarItem>
              //   <MDBNavbarLink href='#'>Features</MDBNavbarLink>
              // </MDBNavbarItem>
              <MDBNavbarItem>
                <MDBNavbarLink href='#'>Pricing</MDBNavbarLink>
              </MDBNavbarItem>
              <MDBNavbarItem>
                <MDBNavbarLink href='#'>About</MDBNavbarLink>
              </MDBNavbarItem>
            </MDBNavbarNav> */}
          </MDBCollapse>
        </MDBContainer>
      </MDBNavbar>

      <div
        className='p-5 text-center bg-image'
        //style={{ backgroundImage: "url('https://mdbootstrap.com/img/new/slides/041.webp')", height: '400px' }}
        style={{ backgroundImage: `url(${img})`, height: '500px' }}
      >


      
        {/* <div className='mask' style={{ backgroundColor: 'rgba(0, 0, 0, 0.6)' }}>
          <div className='d-flex justify-content-center align-items-center h-100'>
            <div className='text-white'>
             
              <MDBBtn tag="a" outline size="lg">
                Home
              </MDBBtn>

              <MDBBtn href="https://wisemanconference.org" target="_blank" color="primary">
  Home
</MDBBtn>
            </div>
          </div>
        </div> */}
      </div>
    </header>
      <MDBContainer className="py-5 h-100">
        <MDBRow className="justify-content-center my-4">
          <MDBCol md="8">
          <MDBCard className="mb-4">
         
              <MDBCardBody>
              <MDBTypography tag="h5" className="mb-0">
                  International Wisemen Conference
                </MDBTypography>
                

                <p></p>
                <p>
                  <strong>Event details</strong>
                </p>
                <p className="mb-0">Join us at the Wisemen Conference where men of integrity gather to unlock the secrets of wisdom ,leadership and purpose # The Legacy Continues 🌟</p>
        <p>.</p>
<p> 
  <strong>Location</strong>
                </p>
                <p>Ezekiel Guti Conventional Center --AMFCC</p>
              
                <p>
                  <strong>Date & Time</strong>
                </p>
                <p>12-15 September 2024</p>
              
              
              </MDBCardBody>
            </MDBCard>
            <MDBCard className="mb-4">
              <MDBCardHeader className="py-3">
                <MDBTypography tag="h5" className="mb-0">
                International Wisemen Conference
                </MDBTypography>
              </MDBCardHeader>
              <MDBCardBody>
                <MDBRow>
                  <MDBCol lg="3" md="12" className="mb-4 mb-lg-0">
                    <MDBRipple rippleTag="div" rippleColor="light"
                      className="bg-image rounded hover-zoom hover-overlay">
                      <img
                        src={img2}
                        className="w-100" />
                      <a href="#!">
                        <div className="mask" style={{ backgroundColor: "rgba(251, 251, 251, 0.2)" , }}>
                        
                        </div>
                      </a>
                    </MDBRipple>
                  </MDBCol>
    
                  {/* <MDBCol lg="5" md="6" className=" mb-4 mb-lg-0">
                    <p>
                      <strong>Standard Ticket</strong>
                    </p> 
                    <p >
                      <strong>ZWL 24000.98</strong>
                     
                    </p>

                    <p > <strong>USD 3</strong></p>
    
                    <MDBTooltip wrapperProps={{ size: "sm" }} wrapperClass="me-1 mb-2"
                      title="Remove item">
                      <MDBIcon fas icon="trash" />
                    </MDBTooltip>
    
                    <MDBTooltip wrapperProps={{ size: "sm" , color: "danger" }} wrapperClass="me-1 mb-2"
                      title="Move to the wish list">
                      <MDBIcon fas icon="heart" />
                    </MDBTooltip>


                    <p>
                      <strong>Combo Ticket(including Regalia & Refreshments)</strong>
                    </p> 
                    <p >
                      <strong>ZWL 24000.98</strong>
                     
                    </p>

                    <p > <strong>USD 10</strong></p>
    
                     <MDBTooltip wrapperProps={{ size: "sm" }} wrapperClass="me-1 mb-2"
                      title="Remove item">
                      <MDBIcon fas icon="trash" />
                    </MDBTooltip>
    
                    <MDBTooltip wrapperProps={{ size: "sm" , color: "danger" }} wrapperClass="me-1 mb-2"
                      title="Move to the wish list">
                      <MDBIcon fas icon="heart" />
                    </MDBTooltip> 
                  </MDBCol> */}

                  
                  <MDBCol lg="4" md="6" className="mb-4 mb-lg-0">
                    <div className="d-flex mb-4" style={{ maxWidth: "300px" }}>
                      <MDBBtn className="px-3 me-2">
                        <MDBIcon fas icon="minus" />
                      </MDBBtn>
    
                      <MDBInput defaultValue={1} min={0} type="number" label="Quantity" />
    
                      <MDBBtn className="px-3 ms-2">
                        <MDBIcon fas icon="plus" />
                      </MDBBtn>
                    </div>
    
                  
                  </MDBCol>
                </MDBRow>
    
                <hr className="my-4" />
    
            
              </MDBCardBody>
            </MDBCard>
    
           
    
            <MDBCard className="mb-4 mb-lg-0">
              <MDBCardBody>
                <p>
                  <strong>We accept</strong>
                </p>
                <MDBCardImage className="me-2" width="45px"
                  src={eco}
                  alt="Visa" />
               
                <MDBCardImage className="me-2" width="45px"
                  src="https://mdbcdn.b-cdn.net/wp-content/plugins/woocommerce-gateway-stripe/assets/images/mastercard.svg"
                  alt="Mastercard" />

<MDBCardImage className="me-2" width="45px"
                  src={visa}
                  alt="Visa" />

<MDBCardImage className="me-2" width="45px"
                  src={innbucks}
                  alt="Visa" />
                <MDBCardImage className="me-2" width="45px"
                 // src="https://mdbcdn.b-cdn.net/wp-content/plugins/woocommerce/includes/gateways/paypal/assets/images/paypal.png"
                 src={zim}
                  alt="Zimswitch" />
              </MDBCardBody>

{/* FAQS */}




            </MDBCard>

<div>.</div>


            {/* <MDBCard className="mb-4">
            <MDBCardHeader className="py-3">
                <MDBTypography tag="h5" className="mb-0">
                  FAQ
                </MDBTypography>
              </MDBCardHeader>
              <MDBCardBody>

              <MDBContainer className="mt-5" style={{maxWidth: '1000px'}}>
      <MDBAccordion alwaysOpen initialActive={1}>
        
        <MDBAccordionItem collapseId={1} headerTitle="Question #1 .Is there an age restriction for this event?">
        There is no age restriction for this event. However, please ensure that you are responsible for any participants you bring, whether they are young or elderly
        </MDBAccordionItem>
       
        <MDBAccordionItem collapseId={2} headerTitle="Question #2 .Can I register at the event?">
         
         Yes you can register at the event though preregistration is encouraged
         
       </MDBAccordionItem>
      </MDBAccordion>
    </MDBContainer>
              </MDBCardBody>
            </MDBCard> */}
            
          </MDBCol>
          <MDBCol md="4">
            <MDBCard className="mb-4">
              <MDBCardHeader>
                <MDBTypography tag="h5" className="mb-0">
                  USD200
                </MDBTypography>
              </MDBCardHeader>
              <MDBCardBody>
                <MDBListGroup flush>
                  <MDBListGroupItem
                    className="d-flex justify-content-between align-items-center border-0 px-0 pb-0">
                    Bay 01
                    {/* <span>ZWL 24000.98</span> */}
                  </MDBListGroupItem>
                  <MDBListGroupItem
                    className="d-flex justify-content-between align-items-center border-0 px-0 pb-0">
                    Worksuit.Golf Shirt & Cap.Folder/Pen.Parking disc.Lunch & Water.Conference meals
                    {/* <span>ZWL 24000.98</span> */}
                  </MDBListGroupItem>
                  {/* <MDBListGroupItem className="d-flex justify-content-between align-items-center px-0">
                    Shipping
                    <span>Gratis</span>
                  </MDBListGroupItem> */}
                  <MDBListGroupItem
                    className="d-flex justify-content-between align-items-center border-0 px-0 mb-3">
                    <div>
                      <strong>Total amount</strong>
                      <strong>
                        {/* <p className="mb-0">(including VAT)</p> */}
                      </strong>
                    </div>
                    <span>
                      <strong>USD 200</strong>
                    </span>
                  </MDBListGroupItem>
                </MDBListGroup>
                <MDBBtn onClick={toggleOpen}>Get Ticket</MDBBtn>
      <MDBModal open={basicModal} setOpen={setBasicModal} tabIndex='-1'>
        <MDBModalDialog>
          <MDBModalContent>
            <MDBModalHeader>
              <MDBModalTitle>USD 200</MDBModalTitle>
              <MDBBtn className='btn-close' color='none' onClick={toggleOpen}></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody>
            <form>
            {showName && (
        <div>
         
    
          <label  className='form-label'>
         
          <MDBRow className='mb-4'> Please fill out all the details</MDBRow> 
         
        
      </label>
        </div>
      )} 
      <MDBInput wrapperClass='mb-4' id='name' name="name" value={formData.name}  label='Ticket holder name' onChange={handleChangey}    invalid={errors.name ? "true" : "false"}/>
     
     
      <MDBInput wrapperClass='mb-4' id='surname' name="surname" value={formData.surname}  label='Ticket holder surname'  onChange={handleChangey}  invalid={errors.name ? true : false}/>
      <MDBInput wrapperClass='mb-4' id='idnum'  name="idnum" value={formData.idnum}  label='Ticket holder ID' onChange={handleChangey}  invalid={errors.name ? true : false}/>
      <MDBInput wrapperClass='mb-4' id='email'  name="email" value={formData.email}  label='Valid email address for sending confirmation'   onChange={handleChangey}  invalid={errors.name ? true : false}/>
     
        
      <MDBInput wrapperClass='mb-4' id='phone'  name="phone" value={formData.phone}  label='Phone number' onChange={handleChangey}  invalid={errors.name ? true : false}/>
         
          <div className="mb-4">
          
         
        
        
          <label>Select worksuit size</label>
           <select data-mdb-select-init
      onChange={handleSelectChange}
      value={selectedOption}
      className="form-select"
       
>

  <option  value="S">S</option>
  <option  value="M">M</option>
  <option  value="L">L</option>
  <option  value="XL">XL</option>
  <option  value="XXL">XXL</option>
  <option  value="XXXL">XXXL</option>
  
</select>
</div>
      <MDBRow className='mb-4'>
        <MDBCol className='d-flex justify-content-center'>
        {/* <MDBRadio name='group' id='radio1' value='ecocash' checked={selectedValue === 'ecocash'} onChange={() => handleChange('ecocash')} label='Ecocash USD' /> */}
      {/* <MDBRadio name='group' id='radio2' value='onemoney' checked={selectedValue === 'onemoney'} onChange={() => handleChange('onemoney')} label='onemoney' /> */}
        </MDBCol>
       
       
      </MDBRow> 

      {showNot && (
        <div>
         
    
          <label  className='form-label'>
         
          <MDBRow className='mb-4'> You will be redirected to our Paynow payment portal ..Click The Following Button</MDBRow> 
          <MDBRow className='mb-4'> Loading ....</MDBRow> 
        
      </label>
        </div>
      )} 

      {showDiv && (
        <div>
         
          <label  className='form-label'>
          <MDBBtn href={apiData} >Proceed to pay</MDBBtn>
          
         
        
      </label>
        </div>
      )} 
      
    </form>

  

            </MDBModalBody>


            <MDBModalFooter>
              <MDBBtn color='secondary' onClick={toggleOpen}>
                Close
              </MDBBtn>
              <MDBBtn onClick={handleClick} disabled={isDisabled}>Buy Ticket</MDBBtn>
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    
              </MDBCardBody>
            </MDBCard>


           


           {/* another modal */}

            <MDBCard className="mb-4">
              <MDBCardHeader>
                <MDBTypography tag="h5" className="mb-0">
                  USD150
                </MDBTypography>
              </MDBCardHeader>
              <MDBCardBody>
                <MDBListGroup flush>
                <MDBListGroupItem
                    className="d-flex justify-content-between align-items-center border-0 px-0 pb-0">
                   Bay 2 & 3 Front
                    {/* <span>USD 10</span> */}
                  </MDBListGroupItem>
                  <MDBListGroupItem
                    className="d-flex justify-content-between align-items-center border-0 px-0 pb-0">
                   Worksuit.Golf Shirt.Folder/Pen.Parking disc.Lunch & Water.Conference meals
                    {/* <span>USD 10</span> */}
                  </MDBListGroupItem>
                  {/* <MDBListGroupItem className="d-flex justify-content-between align-items-center px-0">
                    Shipping
                    <span>Gratis</span>
                  </MDBListGroupItem> */}
                  <MDBListGroupItem
                    className="d-flex justify-content-between align-items-center border-0 px-0 mb-3">
                    <div>
                      <strong>Total amount</strong>
                      <strong>
                        {/* <p className="mb-0">(including VAT)</p> */}
                      </strong>
                    </div>
                    <span>
                      <strong>USD 150</strong>
                    </span>
                  </MDBListGroupItem>
                </MDBListGroup>
                <MDBBtn onClick={toggleOpeny}>Get Ticket</MDBBtn>
      <MDBModal open={basicModaly} setOpen={setBasicModaly} tabIndex='-1'>
        <MDBModalDialog>
          <MDBModalContent>
            <MDBModalHeader>
              <MDBModalTitle>Payment:USD150</MDBModalTitle>
              <MDBBtn className='btn-close' color='none' onClick={toggleOpeny}></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody>
            <form>

            {showName && (
        <div>
         
    
          <label  className='form-label'>
         
          <MDBRow className='mb-4'> Please fill out all the details</MDBRow> 
         
        
      </label>
        </div>
      )} 
      
      <MDBInput wrapperClass='mb-4' id='name' name="name" value={formData.name}  label='Ticket holder name' onChange={handleChangey}/>
      <MDBInput wrapperClass='mb-4' id='surname' name="surname" value={formData.surname}  label='Ticket holder surname'  onChange={handleChangey} />
      <MDBInput wrapperClass='mb-4' id='idnum' name="idnum" value={formData.idnum}  label='Ticket holder ID'  onChange={handleChangey} />
      <MDBInput wrapperClass='mb-4' id='email'  name="email" value={formData.email}  label='Valid email address for sending confirmation'   onChange={handleChangey}/>
      <MDBInput wrapperClass='mb-4' id='phone'  name="phone" value={formData.phone}  label='Phone number' onChange={handleChangey} />
      <div className="mb-4">
          
         
        
        
          <label>Select worksuit size</label>
           <select data-mdb-select-init
      onChange={handleSelectChange}
      value={selectedOption}
      className="form-select"
       
>

  <option  value="S">S</option>
  <option  value="M">M</option>
  <option  value="L">L</option>
  <option  value="XL">XL</option>
  <option  value="XXL">XXL</option>
  <option  value="XXXL">XXXL</option>
  
</select>
</div>
       

      <MDBRow className='mb-4'>
        <MDBCol className='d-flex justify-content-center'>
        {/* <MDBRadio name='group' id='radio1' value='ecocash' checked={selectedValue === 'ecocash'} onChange={() => handleChange('ecocash')} label='Ecocash USD' /> */}
      {/* <MDBRadio name='group' id='radio2' value='onemoney' checked={selectedValue === 'onemoney'} onChange={() => handleChange('onemoney')} label='onemoney' /> */}
        </MDBCol>
       
      </MDBRow> 

      {showNot && (
        <div>
         
    
          <label  className='form-label'>
         
          <MDBRow className='mb-4'> You will be redirected to our Paynow payment portal ..Click The Following Button</MDBRow> 
          <MDBRow className='mb-4'> Loading ......</MDBRow> 
        
      </label>
        </div>
      )} 

      {showDiv && (
        <div>
         
          <label  className='form-label'>
          <MDBBtn href={apiData} >Proceed to pay</MDBBtn>
          
         
        
      </label>
        </div>
      )} 
      
      
    </form>

  

            </MDBModalBody>


            <MDBModalFooter>
              <MDBBtn color='secondary' onClick={toggleOpeny}>
                Close
              </MDBBtn>
              <MDBBtn onClick={handleClicky} disabled={isDisabled}>Buy Ticket</MDBBtn>
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    
              </MDBCardBody>
            </MDBCard>

            
           {/* another modal */}

           <MDBCard className="mb-4">
              <MDBCardHeader>
                <MDBTypography tag="h5" className="mb-0">
                  USD100
                </MDBTypography>
              </MDBCardHeader>
              <MDBCardBody>
                <MDBListGroup flush>
                <MDBListGroupItem
                    className="d-flex justify-content-between align-items-center border-0 px-0 pb-0">
                   Bay 2 & 3 Back /Bay 4 & 5 Front
                    {/* <span>USD 10</span> */}
                  </MDBListGroupItem>
                  <MDBListGroupItem
                    className="d-flex justify-content-between align-items-center border-0 px-0 pb-0">
                   Worksuit.T-Shirt.Folder/Pen.Parking disc.Lunch & Water.Conference meals
                    {/* <span>USD 10</span> */}
                  </MDBListGroupItem>
                  {/* <MDBListGroupItem className="d-flex justify-content-between align-items-center px-0">
                    Shipping
                    <span>Gratis</span>
                  </MDBListGroupItem> */}
                  <MDBListGroupItem
                    className="d-flex justify-content-between align-items-center border-0 px-0 mb-3">
                    <div>
                      <strong>Total amount</strong>
                      <strong>
                        {/* <p className="mb-0">(including VAT)</p> */}
                      </strong>
                    </div>
                    <span>
                      <strong>USD 100</strong>
                    </span>
                  </MDBListGroupItem>
                </MDBListGroup>
                <MDBBtn onClick={toggleOpeny1}>Get Ticket</MDBBtn>
      <MDBModal open={basicModaly1} setOpen={setBasicModaly1} tabIndex='-1'>
        <MDBModalDialog>
          <MDBModalContent>
            <MDBModalHeader>
              <MDBModalTitle>Payment:USD100</MDBModalTitle>
              <MDBBtn className='btn-close' color='none' onClick={toggleOpeny1}></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody>
            <form>

            {showName && (
        <div>
         
    
          <label  className='form-label'>
         
          <MDBRow className='mb-4'> Please fill out all the details</MDBRow> 
         
        
      </label>
        </div>
      )} 
      
      <MDBInput wrapperClass='mb-4' id='name' name="name" value={formData.name}  label='Ticket holder name' onChange={handleChangey}/>
      <MDBInput wrapperClass='mb-4' id='surname' name="surname" value={formData.surname}  label='Ticket holder surname'  onChange={handleChangey} />
      <MDBInput wrapperClass='mb-4' id='idnum' name="idnum" value={formData.idnum}  label='Ticket holder ID'  onChange={handleChangey} />
      <MDBInput wrapperClass='mb-4' id='email'  name="email" value={formData.email}  label='Valid email address for sending confirmation'   onChange={handleChangey}/>
      <MDBInput wrapperClass='mb-4' id='phone'  name="phone" value={formData.phone}  label='Phone number' onChange={handleChangey} />
      <div className="mb-4">
          
         
        
        
          <label>Select worksuit size</label>
           <select data-mdb-select-init
      onChange={handleSelectChange}
      value={selectedOption}
      className="form-select"
       
>

  <option  value="S">S</option>
  <option  value="M">M</option>
  <option  value="L">L</option>
  <option  value="XL">XL</option>
  <option  value="XXL">XXL</option>
  <option  value="XXXL">XXXL</option>
  
</select>
</div>
       

      <MDBRow className='mb-4'>
        <MDBCol className='d-flex justify-content-center'>
        {/* <MDBRadio name='group' id='radio1' value='ecocash' checked={selectedValue === 'ecocash'} onChange={() => handleChange('ecocash')} label='Ecocash USD' /> */}
      {/* <MDBRadio name='group' id='radio2' value='onemoney' checked={selectedValue === 'onemoney'} onChange={() => handleChange('onemoney')} label='onemoney' /> */}
        </MDBCol>
       
      </MDBRow> 

      {showNot && (
        <div>
         
    
          <label  className='form-label'>
         
          <MDBRow className='mb-4'> You will be redirected to Paynow payment portal ..Click The Following Button</MDBRow> 
          <MDBRow className='mb-4'> Loading .....</MDBRow> 
        
      </label>
        </div>
      )} 

      {showDiv && (
        <div>
         
          <label  className='form-label'>
          <MDBBtn href={apiData} >Proceed to pay</MDBBtn>
          
         
        
      </label>
        </div>
      )} 
      
    </form>

  

            </MDBModalBody>


            <MDBModalFooter>
              <MDBBtn color='secondary' onClick={toggleOpeny1}>
                Close
              </MDBBtn>
              <MDBBtn onClick={handleClicky1} disabled={isDisabled}>Buy Ticket</MDBBtn>
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    
              </MDBCardBody>
            </MDBCard>


            
           {/* another modal */}

           <MDBCard className="mb-4">
              <MDBCardHeader>
                <MDBTypography tag="h5" className="mb-0">
                  USD50
                </MDBTypography>
              </MDBCardHeader>
              <MDBCardBody>
                <MDBListGroup flush>
                <MDBListGroupItem
                    className="d-flex justify-content-between align-items-center border-0 px-0 pb-0">
                   Bay 4 & 5 Back 
                    {/* <span>USD 10</span> */}
                  </MDBListGroupItem>
                  <MDBListGroupItem
                    className="d-flex justify-content-between align-items-center border-0 px-0 pb-0">
                    Worksuit.Golf Shirt.Folder/Pen.Lunch & Water.Conference meals
                    {/* <span>USD 10</span> */}
                  </MDBListGroupItem>
                  {/* <MDBListGroupItem className="d-flex justify-content-between align-items-center px-0">
                    Shipping
                    <span>Gratis</span>
                  </MDBListGroupItem> */}
                  <MDBListGroupItem
                    className="d-flex justify-content-between align-items-center border-0 px-0 mb-3">
                    <div>
                      <strong>Total amount</strong>
                      <strong>
                        {/* <p className="mb-0">(including VAT)</p> */}
                      </strong>
                    </div>
                    <span>
                      <strong>USD 50</strong>
                    </span>
                  </MDBListGroupItem>
                </MDBListGroup>
                <MDBBtn onClick={toggleOpeny2}>Get Ticket</MDBBtn>
      <MDBModal open={basicModaly2} setOpen={setBasicModaly2} tabIndex='-1'>
        <MDBModalDialog>
          <MDBModalContent>
            <MDBModalHeader>
              <MDBModalTitle>Payment:USD50</MDBModalTitle>
              <MDBBtn className='btn-close' color='none' onClick={toggleOpeny2}></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody>
            <form>

            {showName && (
        <div>
         
    
          <label  className='form-label'>
         
          <MDBRow className='mb-4'> Please fill out all the details</MDBRow> 
         
        
      </label>
        </div>
      )} 
      
      <MDBInput wrapperClass='mb-4' id='name' name="name" value={formData.name}  label='Ticket holder name' onChange={handleChangey}/>
      <MDBInput wrapperClass='mb-4' id='surname' name="surname" value={formData.surname}  label='Ticket holder surname'  onChange={handleChangey} />
      <MDBInput wrapperClass='mb-4' id='idnum' name="idnum" value={formData.idnum}  label='Ticket holder ID'  onChange={handleChangey} />
      <MDBInput wrapperClass='mb-4' id='email'  name="email" value={formData.email}  label='Valid email address for sending confirmation'   onChange={handleChangey}/>
      <MDBInput wrapperClass='mb-4' id='phone'  name="phone" value={formData.phone}  label='Phone number' onChange={handleChangey} />
          
      <div className="mb-4">
          
         
        
        
          <label>Select worksuit size</label>
           <select data-mdb-select-init
      onChange={handleSelectChange}
      value={selectedOption}
      className="form-select"
       
>

  <option  value="S">S</option>
  <option  value="M">M</option>
  <option  value="L">L</option>
  <option  value="XL">XL</option>
  <option  value="XXL">XXL</option>
  <option  value="XXXL">XXXL</option>
  
</select>
</div>

      <MDBRow className='mb-4'>
        <MDBCol className='d-flex justify-content-center'>
        {/* <MDBRadio name='group' id='radio1' value='ecocash' checked={selectedValue === 'ecocash'} onChange={() => handleChange('ecocash')} label='Ecocash USD' /> */}
      {/* <MDBRadio name='group' id='radio2' value='onemoney' checked={selectedValue === 'onemoney'} onChange={() => handleChange('onemoney')} label='onemoney' /> */}
        </MDBCol>
       
      </MDBRow> 

      {showNot && (
        <div>
         
    
          <label  className='form-label'>
         
         {showNot && (
        <div>
         
    
          <label  className='form-label'>
         
          <MDBRow className='mb-4'> You will be redirected to our Paynow payment portal ..Click The Following Button</MDBRow> 
          <MDBRow className='mb-4'> Loading .....</MDBRow> 
        
      </label>
        </div>
      )} 

      {showDiv && (
        <div>
         
          <label  className='form-label'>
          <MDBBtn href={apiData} >Proceed to pay</MDBBtn>
          
         
        
      </label>
        </div>
      )} 
      
         
        
      </label>
        </div>
      )} 

      {showDiv && (
        <div>
         
          <label  className='form-label'>
          <MDBBtn href={apiData} >Proceed to pay</MDBBtn>
          
         
        
      </label>
        </div>
      )} 
      
    </form>

  

            </MDBModalBody>


            <MDBModalFooter>
              <MDBBtn color='secondary' onClick={toggleOpeny2}>
                Close
              </MDBBtn>
              <MDBBtn onClick={handleClicky2} disabled={isDisabled}>Buy Ticket</MDBBtn>
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    
              </MDBCardBody>
            </MDBCard>



            
           {/* another modal */}

           <MDBCard className="mb-4">
              <MDBCardHeader>
                <MDBTypography tag="h5" className="mb-0">
                  USD25
                </MDBTypography>
              </MDBCardHeader>
              <MDBCardBody>
                <MDBListGroup flush>
                <MDBListGroupItem
                    className="d-flex justify-content-between align-items-center border-0 px-0 pb-0">
                   Bay 6 -15
                    {/* <span>USD 10</span> */}
                  </MDBListGroupItem>
                  <MDBListGroupItem
                    className="d-flex justify-content-between align-items-center border-0 px-0 pb-0">
                    Writting pad .Conference meals
                    {/* <span>USD 10</span> */}
                  </MDBListGroupItem>
                  {/* <MDBListGroupItem className="d-flex justify-content-between align-items-center px-0">
                    Shipping
                    <span>Gratis</span>
                  </MDBListGroupItem> */}
                  <MDBListGroupItem
                    className="d-flex justify-content-between align-items-center border-0 px-0 mb-3">
                    <div>
                      <strong>Total amount</strong>
                      <strong>
                        {/* <p className="mb-0">(including VAT)</p> */}
                      </strong>
                    </div>
                    <span>
                      <strong>USD 25</strong>
                    </span>
                  </MDBListGroupItem>
                </MDBListGroup>
                <MDBBtn onClick={toggleOpeny3}>Get Ticket</MDBBtn>
      <MDBModal open={basicModaly3} setOpen={setBasicModaly3} tabIndex='-1'>
        <MDBModalDialog>
          <MDBModalContent>
            <MDBModalHeader>
              <MDBModalTitle>Payment:USD25</MDBModalTitle>
              <MDBBtn className='btn-close' color='none' onClick={toggleOpeny3}></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody>
            <form>

            {showName && (
        <div>
         
    
          <label  className='form-label'>
         
          <MDBRow className='mb-4'> Please fill out all the details</MDBRow> 
         
        
      </label>
        </div>
      )} 
      
      <MDBInput wrapperClass='mb-4' id='name' name="name" value={formData.name}  label='Ticket holder name' onChange={handleChangey}/>
      <MDBInput wrapperClass='mb-4' id='surname' name="surname" value={formData.surname}  label='Ticket holder surname'  onChange={handleChangey} />
      <MDBInput wrapperClass='mb-4' id='idnum' name="idnum" value={formData.idnum}  label='Ticket holder ID'  onChange={handleChangey} />
      <MDBInput wrapperClass='mb-4' id='email'  name="email" value={formData.email}  label='Valid email address for sending confirmation'   onChange={handleChangey}/>
      <MDBInput wrapperClass='mb-4' id='phone'  name="phone" value={formData.phone}  label='Phone number' onChange={handleChangey} />
          
       

      <MDBRow className='mb-4'>
        <MDBCol className='d-flex justify-content-center'>
        {/* <MDBRadio name='group' id='radio1' value='ecocash' checked={selectedValue === 'ecocash'} onChange={() => handleChange('ecocash')} label='Ecocash USD' /> */}
      {/* <MDBRadio name='group' id='radio2' value='onemoney' checked={selectedValue === 'onemoney'} onChange={() => handleChange('onemoney')} label='onemoney' /> */}
        </MDBCol>
       
      </MDBRow> 

      {showNot && (
        <div>
         
    
          <label  className='form-label'>
         
          <MDBRow className='mb-4'> You will be redirected to our Paynow payment portal ..Click The Following Button</MDBRow> 
          <MDBRow className='mb-4'> Loading .....</MDBRow> 
        
      </label>
        </div>
      )} 

      {showDiv && (
        <div>
         
          <label  className='form-label'>
          <MDBBtn href={apiData} >Proceed to pay</MDBBtn>
          
         
        
      </label>
        </div>
      )} 
      
      
    </form>

  

            </MDBModalBody>


            <MDBModalFooter>
              <MDBBtn color='secondary' onClick={toggleOpeny3}>
                Close
              </MDBBtn>
              <MDBBtn onClick={handleClicky3} disabled={isDisabled}>Buy Ticket</MDBBtn>
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    
              </MDBCardBody>
            </MDBCard>



            
           {/* another modal */}

           <MDBCard className="mb-4">
              <MDBCardHeader>
                <MDBTypography tag="h5" className="mb-0">
                  USD10
                </MDBTypography>
              </MDBCardHeader>
              <MDBCardBody>
                <MDBListGroup flush>
                <MDBListGroupItem
                    className="d-flex justify-content-between align-items-center border-0 px-0 pb-0">
                   Bay 16-35
                    {/* <span>USD 10</span> */}
                  </MDBListGroupItem>
                  <MDBListGroupItem
                    className="d-flex justify-content-between align-items-center border-0 px-0 pb-0">
                    Conference meals
                    {/* <span>USD 10</span> */}
                  </MDBListGroupItem>
                  {/* <MDBListGroupItem className="d-flex justify-content-between align-items-center px-0">
                    Shipping
                    <span>Gratis</span>
                  </MDBListGroupItem> */}
                  <MDBListGroupItem
                    className="d-flex justify-content-between align-items-center border-0 px-0 mb-3">
                    <div>
                      <strong>Total amount</strong>
                      <strong>
                        {/* <p className="mb-0">(including VAT)</p> */}
                      </strong>
                    </div>
                    <span>
                      <strong>USD 10</strong>
                    </span>
                  </MDBListGroupItem>
                </MDBListGroup>
                <MDBBtn onClick={toggleOpeny4}>Get Ticket</MDBBtn>
      <MDBModal open={basicModaly4} setOpen={setBasicModaly4} tabIndex='-1'>
        <MDBModalDialog>
          <MDBModalContent>
            <MDBModalHeader>
              <MDBModalTitle>Payment:USD 10</MDBModalTitle>
              <MDBBtn className='btn-close' color='none' onClick={toggleOpeny4}></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody>
            <form>

            {showName && (
        <div>
         
    
          <label  className='form-label'>
         
          <MDBRow className='mb-4'> Please fill out all the details</MDBRow> 
         
        
      </label>
        </div>
      )} 
      
      <MDBInput wrapperClass='mb-4' id='name' name="name" value={formData.name}  label='Ticket holder name' onChange={handleChangey}/>
      <MDBInput wrapperClass='mb-4' id='surname' name="surname" value={formData.surname}  label='Ticket holder surname'  onChange={handleChangey} />
      <MDBInput wrapperClass='mb-4' id='idnum' name="idnum" value={formData.idnum}  label='Ticket holder ID'  onChange={handleChangey} />
      <MDBInput wrapperClass='mb-4' id='email'  name="email" value={formData.email}  label='Valid email address for sending confirmation'   onChange={handleChangey}/>
      <MDBInput wrapperClass='mb-4' id='phone'  name="phone" value={formData.phone}  label='Phone number' onChange={handleChangey} />
          
       

      <MDBRow className='mb-4'>
        <MDBCol className='d-flex justify-content-center'>
        {/* <MDBRadio name='group' id='radio1' value='ecocash' checked={selectedValue === 'ecocash'} onChange={() => handleChange('ecocash')} label='Ecocash USD' /> */}
      {/* <MDBRadio name='group' id='radio2' value='onemoney' checked={selectedValue === 'onemoney'} onChange={() => handleChange('onemoney')} label='onemoney' /> */}
        </MDBCol>
       
      </MDBRow> 

      {showNot && (
        <div>
         
    
          <label  className='form-label'>
         
          <MDBRow className='mb-4'> You will be redirected to our Paynow payment portal ..Click The Following Button</MDBRow> 
          <MDBRow className='mb-4'> Loading ......</MDBRow> 
        
      </label>
        </div>
      )} 

      {showDiv && (
        <div>
         
          <label  className='form-label'>
          <MDBBtn href={apiData} >Proceed to pay</MDBBtn>
          
         
        
      </label>
        </div>
      )} 
      
      
    </form>

  

            </MDBModalBody>


            <MDBModalFooter>
              <MDBBtn color='secondary' onClick={toggleOpeny4}>
                Close
              </MDBBtn>
              <MDBBtn onClick={handleClicky4} disabled={isDisabled}>Buy Ticket</MDBBtn>
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    
              </MDBCardBody>
            </MDBCard>


            <MDBCard className="mb-4">
              <MDBCardHeader>
                <MDBTypography tag="h5" className="mb-0">
                  Online Experience 
                </MDBTypography>
              </MDBCardHeader>
              <MDBCardBody>
                <MDBListGroup flush>
                <MDBListGroupItem
                    className="d-flex justify-content-between align-items-center border-0 px-0 pb-0">
                   Online Experience
                    {/* <span>USD 10</span> */}
                  </MDBListGroupItem>
                  {/* <MDBListGroupItem
                    className="d-flex justify-content-between align-items-center border-0 px-0 pb-0">
                    Conference meals
                   
                  </MDBListGroupItem> */}
                  {/* <MDBListGroupItem className="d-flex justify-content-between align-items-center px-0">
                    Shipping
                    <span>Gratis</span>
                  </MDBListGroupItem> */}
                  <MDBListGroupItem
                    className="d-flex justify-content-between align-items-center border-0 px-0 mb-3">
                    <div>
                      <strong>Total amount</strong>
                      <strong>
                        {/* <p className="mb-0">(including VAT)</p> */}
                      </strong>
                    </div>
                    <span>
                      <strong>USD 5</strong>
                    </span>
                  </MDBListGroupItem>
                </MDBListGroup>
                <MDBBtn onClick={toggleOpeny5}>Get Ticket</MDBBtn>
      <MDBModal open={basicModaly5} setOpen={setBasicModaly5} tabIndex='-1'>
        <MDBModalDialog>
          <MDBModalContent>
            <MDBModalHeader>
              <MDBModalTitle>Payment:USD 5</MDBModalTitle>
              <MDBBtn className='btn-close' color='none' onClick={toggleOpeny5}></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody>
            <form>

            {showName && (
        <div>
         
    
          <label  className='form-label'>
         
          <MDBRow className='mb-4'> Please fill out all the details</MDBRow> 
         
        
      </label>
        </div>
      )} 
      
      <MDBInput wrapperClass='mb-4' id='name' name="name" value={formData.name}  label='Ticket holder name' onChange={handleChangey}/>
      <MDBInput wrapperClass='mb-4' id='surname' name="surname" value={formData.surname}  label='Ticket holder surname'  onChange={handleChangey} />
      <MDBInput wrapperClass='mb-4' id='idnum' name="idnum" value={formData.idnum}  label='Ticket holder ID'  onChange={handleChangey} />
      <MDBInput wrapperClass='mb-4' id='email'  name="email" value={formData.email}  label='Valid email address for sending confirmation'   onChange={handleChangey}/>
      <MDBInput wrapperClass='mb-4' id='phone'  name="phone" value={formData.phone}  label='Phone number' onChange={handleChangey} />
          
       

      <MDBRow className='mb-4'>
        <MDBCol className='d-flex justify-content-center'>
        {/* <MDBRadio name='group' id='radio1' value='ecocash' checked={selectedValue === 'ecocash'} onChange={() => handleChange('ecocash')} label='Ecocash USD' /> */}
      {/* <MDBRadio name='group' id='radio2' value='onemoney' checked={selectedValue === 'onemoney'} onChange={() => handleChange('onemoney')} label='onemoney' /> */}
        </MDBCol>
       
      </MDBRow> 

      {showNot && (
        <div>
         
    
          <label  className='form-label'>
         
          <MDBRow className='mb-4'> You will be redirected to our Paynow payment portal ..Click The Following Button</MDBRow> 
          <MDBRow className='mb-4'> Loading ......</MDBRow> 
        
      </label>
        </div>
      )} 

      {showDiv && (
        <div>
         
          <label  className='form-label'>
          <MDBBtn href={apiData} >Proceed to pay</MDBBtn>
          
         
        
      </label>
        </div>
      )} 
      
      
    </form>

  

            </MDBModalBody>


            <MDBModalFooter>
              <MDBBtn color='secondary' onClick={toggleOpeny5}>
                Close
              </MDBBtn>
              <MDBBtn onClick={handleClicky5} disabled={isDisabled}>Buy Ticket</MDBBtn>
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    
              </MDBCardBody>
            </MDBCard>

            
           
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </section>

    </>);
}