
import React, { useState } from 'react';

import {
MDBBtn,
MDBCard,
MDBCardBody,
MDBCardHeader,
MDBCardImage,
MDBCol,
MDBContainer,
MDBIcon,
MDBInput,
MDBListGroup,
MDBListGroupItem,
MDBRipple,
MDBRow,
MDBTooltip,
MDBTypography,
MDBNavbar,
MDBNavbarNav,
MDBNavbarItem,
MDBNavbarLink,
MDBNavbarToggler,
MDBModal, MDBModalDialog, MDBModalContent, MDBModalHeader, MDBModalTitle, MDBModalBody, MDBModalFooter ,
MDBProgress ,
MDBCollapse,
MDBRadio ,

  MDBCheckbox,
  MDBAccordion, MDBAccordionItem


} from "mdb-react-ui-kit";

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './App.css'
import axios from 'axios';

import img from "../src/images/wisemen.png"

import img2 from "../src/images/flyrr.jpeg"
import img3 from "../src/images/fly1.jpeg"

import eco from "../src/images/208274.svg"

import one from "../src/images/onemoney.png"

import { BrowserRouter as Router, Routes, Route, Link ,useParams } from "react-router-dom";
import { Dashboard } from './pages/Dashboard';
import  Notification from './pages/Notification';
function App() {
  const [showBasic, setShowBasic] = useState(false);
  const [basicModal, setBasicModal] = useState(false);

  const [showBasicy, setShowBasicy] = useState(false);
  const [basicModaly, setBasicModaly] = useState(false);
 
  const [basicModaly1, setBasicModaly1] = useState(false);
  const [basicModaly2, setBasicModaly2] = useState(false);
  const [basicModaly3, setBasicModaly3] = useState(false);
  const [basicModaly4, setBasicModaly4] = useState(false);
  const [showDiv, setShowDiv] = useState(false);
   
  const [showNot, setShowNot] = useState(false);
  
  const [apiData, setApiData] = useState(null);
  const [selectedValue, setSelectedValue] = useState('');

  const handleChange = (value) => {
    setSelectedValue(value);
  }
  const notify = () => {
    toast('Notification!');
  }

  const [formData, setFormData] = useState({ name: '', surname: '' ,email:'',phone:'' ,idnum:'' });
  const toggleOpen = () => {setBasicModal(!basicModal) ;    
    setFormData({name: '', surname: '' ,email:'',phone:'',idnum:'' })
    setSelectedValue('');
    setShowDiv(false)
    setShowNot(false)
    
    }

    const toggleOpeny = () => {setBasicModaly(!basicModaly) ;    
      setFormData({name: '', surname: '' ,email:'',phone:'',idnum:'' })
      setSelectedValue('');
      setShowDiv(false)
      setShowNot(false)
      
      }

      const toggleOpeny1 = () => {setBasicModaly1(!basicModaly1) ;    
        setFormData({name: '', surname: '' ,email:'',phone:'',idnum:'' })
        setSelectedValue('');
        setShowDiv(false)
        setShowNot(false)
        
        }

        const toggleOpeny2 = () => {setBasicModaly2(!basicModaly2) ;    
          setFormData({name: '', surname: '' ,email:'',phone:'',idnum:'' })
          setSelectedValue('');
          setShowDiv(false)
          setShowNot(false)
          
          }

          const toggleOpeny3 = () => {setBasicModaly3(!basicModaly3) ;    
            setFormData({name: '', surname: '' ,email:'',phone:'',idnum:'' })
            setSelectedValue('');
            setShowDiv(false)
            setShowNot(false)
            
            }

            const toggleOpeny4 = () => {setBasicModaly4(!basicModaly4) ;    
              setFormData({name: '', surname: '' ,email:'',phone:'',idnum:'' })
              setSelectedValue('');
              setShowDiv(false)
              setShowNot(false)
              
              }
  const handleClick = async () => {
    setShowNot(!showNot)
    let post_data = {
      name: formData.name,
      surname: formData.surname,
      email: formData.email,
      phone: formData.phone,
      idnum:formData.idnum,
      currency: "USD",
      tid: 1,
      tname:"USD200",
      doneby:"website",
      amount:"200"
    };
    try {
      let order_responce = await axios.post(
        "https://onlinecombinedbackend.onrender.com/online/create",
        post_data
      );
      let order = order_responce.data;
      let success = order.success;
      
      if (success === "true") {
       
        
      setApiData(order.message);
      console.log(order.message)
      setShowDiv(true);


    }
    else {
      setApiData(order.message);
    
      setShowDiv(true);
    
    }
  } catch (error) {
    console.error(error);
    
    setApiData(order.message);
    
    setShowDiv(true);
  }
}


//combo

const handleClicky = async () => {
  console.log(selectedValue)
  setShowNot(!showNot)
  let post_data = {
    name: formData.name,
    surname: formData.surname,
    email: formData.email,
    phone: formData.phone,
    idnum:formData.idnum,
    currency: "USD",
    tid: 1,
    tname:"USD150",
    doneby:"website",
    amount:"150"
  };
  try {
    let order_responce = await axios.post(
      "https://onlinecombinedbackend.onrender.com/online/create",
      post_data
    );
    let order = order_responce.data;
    let success = order.success;
    
    if (success === "true") {
     
      
    setApiData(order.message);
    console.log(order.message)
    setShowDiv(true);


  }
  else {
    setApiData(order.message);
  
    setShowDiv(true);
  
  }
} catch (error) {
  console.error(error);
  
  setApiData(order.message);
  
  setShowDiv(true);
}
}


//100

const handleClicky1 = async () => {
  console.log(selectedValue)
  setShowNot(!showNot)
  let post_data = {
    name: formData.name,
    surname: formData.surname,
    email: formData.email,
    phone: formData.phone,
    idnum:formData.idnum,
    currency: "USD",
    tid: 1,
    tname:"USD100",
    doneby:"website",
    amount:"100"
  };
  try {
    let order_responce = await axios.post(
      "https://onlinecombinedbackend.onrender.com/online/create",
      post_data
    );
    let order = order_responce.data;
    let success = order.success;
    
    if (success === "true") {
     
      
    setApiData(order.message);
    console.log(order.message)
    setShowDiv(true);


  }
  else {
    setApiData(order.message);
  
    setShowDiv(true);
  
  }
} catch (error) {
  console.error(error);
  
  setApiData(order.message);
  
  setShowDiv(true);
}
}


//combo

const handleClicky2 = async () => {
  console.log(selectedValue)
  setShowNot(!showNot)
  let post_data = {
    name: formData.name,
    surname: formData.surname,
    email: formData.email,
    phone: formData.phone,
    idnum:formData.idnum,
    currency: "USD",
    tid: 1,
    tname:"USD50",
    doneby:"website",
    amount:"50"
  };
  try {
    let order_responce = await axios.post(
      "https://onlinecombinedbackend.onrender.com/online/create",
      post_data
    );
    let order = order_responce.data;
    let success = order.success;
    
    if (success === "true") {
     
      
    setApiData(order.message);
    console.log(order.message)
    setShowDiv(true);


  }
  else {
    setApiData(order.message);
  
    setShowDiv(true);
  
  }
} catch (error) {
  console.error(error);
  
  setApiData(order.message);
  
  setShowDiv(true);
}
}


//combo

const handleClicky3 = async () => {
  console.log(selectedValue)
  setShowNot(!showNot)
  let post_data = {
    name: formData.name,
    surname: formData.surname,
    email: formData.email,
    phone: formData.phone,
    idnum:formData.idnum,
    currency: "USD",
    tid: 1,
    tname:"USD25",
    doneby:"website",
    amount:"25"
  };
  try {
    let order_responce = await axios.post(
      "https://onlinecombinedbackend.onrender.com/online/create",
      post_data
    );
    let order = order_responce.data;
    let success = order.success;
    
    if (success === "true") {
     
      
    setApiData(order.message);
    console.log(order.message)
    setShowDiv(true);


  }
  else {
    setApiData(order.message);
  
    setShowDiv(true);
  
  }
} catch (error) {
  console.error(error);
  
  setApiData(order.message);
  
  setShowDiv(true);
}
}


//combo

const handleClicky4 = async () => {
  console.log(selectedValue)
  setShowNot(!showNot)
  let post_data = {
    name: formData.name,
    surname: formData.surname,
    email: formData.email,
    phone: formData.phone,
    idnum:formData.idnum,
    currency: "USD",
    tid: 1,
    tname:"USD10",
    doneby:"website",
    amount:"10"
  };
  try {
    let order_responce = await axios.post(
      "https://onlinecombinedbackend.onrender.com/online/create",
      post_data
    );
    let order = order_responce.data;
    let success = order.success;
    
    if (success === "true") {
     
      
    setApiData(order.message);
    console.log(order.message)
    setShowDiv(true);


  }
  else {
    setApiData(order.message);
  
    setShowDiv(true);
  
  }
} catch (error) {
  console.error(error);
  
  setApiData(order.message);
  
  setShowDiv(true);
}
}
  const [showProgress, setShowProgress] = useState(false);

  
  // const handleClick = () => {
    
  //   setShowDiv(!showDiv);
  //   setShowNot(!showNot)
  //  console.log(formData)

  // }

  // const handleChangey = (e) => {
  //   setFormData({ ...formData, [e.target.name]: e.target.value });
  // }


  return (
    <Router>
      <Routes>
          <Route exact path="/" element={<Dashboard/>}/>
          {/* <Route exact path="/notify/:inv/:name/:surname/:email/:phone/:idnum/:tname/:amount/:size" element={<Notification/>}/> */}
          <Route exact path="/notify" element={<Notification/>}/>
        </Routes>
    </Router>
        );
}

export default App;
