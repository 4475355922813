
import { BrowserRouter as Router, Routes, Route, Link ,useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";

import {
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBCardHeader,
  MDBCardFooter,
  MDBContainer,
  MDBRow,
  MDBBtn,
  MDBCol,
  MDBIcon

} from 'mdb-react-ui-kit';

import axios from 'axios';




const Notification = () => {

  const [apiData, setApiData] = useState(null);


  let params = useParams();
  //const [tname, setTname] = useState(params.val);
  const p ="kol"
  const [showDiv, setShowDiv] = useState(false);
  const [showNot, setShowNot] = useState(false);

  const [isDisabled, setIsDisabled] = useState(false);
  const handleClick = async () => {
    setIsDisabled(true);
    setShowNot(!showNot)
    let post_data = {
      name: params.name,
      surname: params.surname,
      email: params.email,
      phone:params.phone,
      idnum:params.idnum,
      currency: "USD",
      tid: 1,
      tname:params.tname,
      doneby:"website",
      amount:params.amount,
      inv:params.inv,
      size:params.size
    };
    try {
      let order_responce = await axios.post(
       // "https://onlinecombinedbackend.onrender.com/online/late",
        "https://onlinecombinedbackend.onrender.com/online/late",
        post_data
      );
      let order = order_responce.data;
      let success = order.success;
      
      if (success === "true") {
       
        
      setApiData(order.message);
      console.log(order.message)
      setShowDiv(true);
  
  
    }
    else {
      setApiData(order.message);
    
      setShowDiv(true);
    
    }
  } catch (error) {
    console.error(error);
    
    setApiData(order.message);
    
    setShowDiv(true);
  }
  }
  

    // let params = useParams()



    return<>
    <section className="h-100 gradient-custom">
      <MDBContainer>
      <MDBRow className="justify-content-center my-4">
      <MDBCol size="6">
      <MDBCard alignment='center'>
      <MDBCardHeader>Notification</MDBCardHeader>
      <MDBCardBody>
        <MDBCardTitle>Thank You</MDBCardTitle>
        <MDBCardText>Check Your Email For Notifications</MDBCardText>
        {/* <MDBIcon fas icon="check" /> */}
        <MDBIcon fas icon="check-circle" />
        {/* <MDBIcon fas icon="check-double" /> */}
        {/* <MDBBtn onClick={handleClick} disabled={isDisabled}>Check Ticket Number</MDBBtn> */}

        {showNot && (
        <div>
         
          <label  className='form-label'>
         
          
          <MDBRow className='mb-4'> Loading .............</MDBRow> 
      </label>
        </div>
      )} 
      
     { showDiv && (
        <div>
         
          <label  className='form-label'>
          <MDBRow className='mb-4'>   Transaction Status : {apiData} </MDBRow> 
         
        
      </label>
        </div>
      )} 
      </MDBCardBody>
      <MDBCardFooter className='text-muted'>
       </MDBCardFooter>
    </MDBCard>
    </MDBCol>
    </MDBRow>
         </MDBContainer>
    </section>
   
    </>
  ;}
  export default Notification;